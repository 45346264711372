import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function TjlApp() {

  const data = useStaticQuery(graphql`{
  header: file(relativePath: {eq: "tjl-smatphones3.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`)




  return (
    <Layout>
      <SEO title="Referencie" />

      <GatsbyImage
        image={data.header.childImageSharp.gatsbyImageData}
        height="650"
        alt="Webová aplikácia" />  
      <div className="section-pd section-pd-bottom white-bg">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <h1>Webová aplikácia pre spoločnosť, ktorá vracia dane zo zahraničia.</h1>
              <p>Pripravovali sme mobile first (s dizajnom najmä pre mobily) webovú aplikáciu, ktorá uľahčuje spoločnosti vytvárať formuláre pre svoje služby, ktoré sa týkajú vráteniu daní zo zahraničia a iných. Aplikácia digitalizuje okamžite odpovede klientov a posiela ich priamo na úrady v zahraničí.</p>
            </div>
          </div>
        </div>
      </div>
     
         
          

      
      



      <div className="ref-buttons">
        <AniLink className="back-to-ref" cover direction="left" to="/referencie" bg="#000" duration={1}>Späť na referencie</AniLink>
        <AniLink className="next-project" cover direction="left" to="/referencie/kindergeld" bg="#000" duration={1}>Ďalší projekt</AniLink>
        
      </div>

    </Layout>
  );

}
